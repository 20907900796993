export default {
  BUTTON_PRIMARY_CLASS: 'button button--primary cm_primary',
  BUTTON_SECONDARY_CLASS: 'button button--transparent cm_secondary',

  LOAD_MORE_BUTTON_CLASS: 'button button--primary cm_primary',

  FACET_INPUT_CLASS: 'form-input',
  MIN_PRICE: 'Min',
  MAX_PRICE: 'Max',

  SELECT_YOUR_VEHICLE_BLOCK: 'Select Your Vehicle',

  GARAGE_BUTTON_CLASS: 'navUser-action',
  GARAGE_SIZE: '<span class="garage-size countPill" key="garage-size">{{size}}</span>',

  FITMENT_TABLE_VEHICLE_SPECIFIC: 'This Part Fits:',

  FACET_BUTTON_CLASS: '',
  SEARCH_BOX_INPUT_CLASS: 'form-input',
  SEARCH_BOX_BUTTON_CLASS: 'btn-search',
};

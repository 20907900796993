
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function verifyFitmentRT () {
    function repeatSelects1(selects, selectsIndex) {
        return [selects(function () {
                function repeatEntry1(popularEntries, unpopularEntries, isPopularField, entry, entryIndex) {
                    return _createElement('option', {
                        'disabled': this.loading,
                        'value': entry.term,
                        'key': entry.value
                    }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                }
                function repeatEntry2(popularEntries, unpopularEntries, isPopularField, entry, entryIndex) {
                    return _createElement('option', {
                        'disabled': this.loading,
                        'value': entry.term,
                        'key': entry.value
                    }, '\n          ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n        ');
                }
                function scopePopularEntriesUnpopularEntriesIsPopularField3() {
                    var popularEntries = this.entries.filter(entry => entry.payload === 'Popular');
                    var unpopularEntries = this.entries.filter(entry => entry.payload !== 'Popular');
                    var isPopularField = popularEntries.length && unpopularEntries.length;
                    return [
                        !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                            'value': '',
                            'key': 'null-option'
                        }, '\n          ', this.title, '\n        ') : null,
                        this.loading ? _createElement('option', {
                            'key': 'loading-option',
                            'disabled': true
                        }, '...loading...') : null,
                        isPopularField ? [
                            _createElement('option', {
                                'className': 'cm_option_title',
                                'disabled': true,
                                'key': '10541'
                            }, 'Popular ', this.title === 'Make' ? 'Makes' : this.title === 'Model' ? 'Models' : 'Values'),
                            _map(popularEntries, repeatEntry1.bind(this, popularEntries, unpopularEntries, isPopularField)),
                            _createElement('option', {
                                'className': 'cm_option_title',
                                'disabled': true,
                                'key': '10544'
                            }, 'Additional ', this.title === 'Make' ? 'Makes' : this.title === 'Model' ? 'Models' : 'Values')
                        ] : null,
                        _map(isPopularField ? unpopularEntries : this.entries, repeatEntry2.bind(this, popularEntries, unpopularEntries, isPopularField))
                    ];
                }
                function scopeShowSelectedValues4() {
                    var showSelectedValues = this.selectedEntries.length;
                    return _createElement('option', {
                        'key': '_current',
                        'value': '_current'
                    }, showSelectedValues ? [this.selectedEntries.map(e => e.value).join(', ')] : null, !showSelectedValues ? [this.title] : null);
                }
                function repeatEntry5(entry, index) {
                    return _createElement('div', {
                        'className': 'option' + (this.isSearchable && this.activeIndex === index || !this.isSearchable && entry.selected ? ' cm_checked' : ''),
                        'key': entry.value,
                        'onClick': () => this.onChange(entry.term)
                    }, this.showCheckboxes ? _createElement('input', {
                        'type': 'checkbox',
                        'readOnly': true,
                        'checked': entry.selected,
                        'className': 'cm_dropdown_checkbox cm_facet_checkbox',
                        'key': '3389'
                    }) : null, _createElement('div', { 'className': 'cm_dropdown_value' }, entry.value), this.showHitCount ? _createElement('div', {
                        'className': 'cm_dropdown_hitCount',
                        'key': '3591'
                    }, entry.hitCount) : null);
                }
                return _createElement('div', { 'className': this.extraClassName }, !this.isSearchable ? _createElement('div', {
                    'className': 'cm_select_toggle' + (this.useNativeDropdown ? ' cm_select_toggle__native' : '') + ' cm_toggle-dropdown',
                    'key': '39'
                }, _createElement('select', {
                    'className': 'cm_select_inner-select cm_select__pretty',
                    'onChange': this.onChange,
                    'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                    'disabled': this.disabled,
                    'aria-label': this.title
                }, this.useNativeDropdown ? scopePopularEntriesUnpopularEntriesIsPopularField3.apply(this, []) : null, !this.useNativeDropdown ? scopeShowSelectedValues4.apply(this, []) : null)) : null, this.isSearchable ? [[this.filterInput(function () {
                            return _createElement('div', { 'className': 'form-input cm_filterInput' });
                        }, {
                            widgetName: 'undefined',
                            items: undefined
                        })]] : null, !this.disabled && !this.useNativeDropdown ? _createElement.apply(this, [
                    'div',
                    {
                        'className': 'cm_dropdown cm_select_dropdown cm_hide',
                        'aria-label': this.title,
                        'key': '2699'
                    },
                    !this.hideNullOption ? _createElement('div', {
                        'className': 'option',
                        'key': 'null-option',
                        'onClick': () => this.onChange('')
                    }, '\n      ', this.title, '\n    ') : null,
                    this.loading ? _createElement('div', {
                        'className': 'option',
                        'key': 'null-option',
                        'disabled': true
                    }, '...loading...') : null,
                    !this.loading ? _map(this.entries, repeatEntry5.bind(this)) : null
                ]) : null);
            }, { count: undefined })];
    }
    function repeatSplittedValue2(verifyFitmentInfo, value, valueIndex, splittedValue, splittedValueIndex) {
        return [_createElement('li', { 'key': '114511' }, splittedValue)];
    }
    function repeatValue3(verifyFitmentInfo, value, valueIndex) {
        return [_map(value.split('\n'), repeatSplittedValue2.bind(this, verifyFitmentInfo, value, valueIndex))];
    }
    function repeatSplittedValue4(verifyFitmentInfo, key, keyIndex, value, valueIndex, splittedValue, splittedValueIndex) {
        return [_createElement('li', { 'key': '120751' }, splittedValue)];
    }
    function repeatValue5(verifyFitmentInfo, key, keyIndex, value, valueIndex) {
        return [_map(value.split('\n'), repeatSplittedValue4.bind(this, verifyFitmentInfo, key, keyIndex, value, valueIndex))];
    }
    function repeatKey6(verifyFitmentInfo, key, keyIndex) {
        return [_createElement('div', {
                'className': 'cm_verify-fitment_vehicle-info',
                'key': '116621'
            }, _createElement('div', { 'className': 'cm_verify-fitment_vehicle-info__key' }, key, ':'), _createElement.apply(this, [
                'ul',
                { 'className': 'cm_verify-fitment_vehicle-info__values' },
                _map(verifyFitmentInfo[key], repeatValue5.bind(this, verifyFitmentInfo, key, keyIndex))
            ]))];
    }
    function scopeVerifyFitmentInfo7() {
        var verifyFitmentInfo = this.productData?.verify_fitment_info;
        return [verifyFitmentInfo && Object.keys(verifyFitmentInfo).length > 0 ? _createElement.apply(this, [
                'div',
                {
                    'className': 'cm_verify-fitment_vehicle-info__container',
                    'key': '110081'
                },
                verifyFitmentInfo['Notes'] ? _createElement.apply(this, [
                    'ul',
                    {
                        'className': 'cm_verify-fitment_vehicle-notes',
                        'key': '11256'
                    },
                    _map(verifyFitmentInfo['Notes'], repeatValue3.bind(this, verifyFitmentInfo))
                ]) : null,
                _map(Object.keys(verifyFitmentInfo).filter(v => v !== 'Notes'), repeatKey6.bind(this, verifyFitmentInfo))
            ]) : null];
    }
    function repeatSelects8(selects, selectsIndex) {
        return [selects(function () {
                function repeatEntry1(popularEntries, unpopularEntries, isPopularField, entry, entryIndex) {
                    return _createElement('option', {
                        'disabled': this.loading,
                        'value': entry.term,
                        'key': entry.value
                    }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                }
                function repeatEntry2(popularEntries, unpopularEntries, isPopularField, entry, entryIndex) {
                    return _createElement('option', {
                        'disabled': this.loading,
                        'value': entry.term,
                        'key': entry.value
                    }, '\n          ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n        ');
                }
                function scopePopularEntriesUnpopularEntriesIsPopularField3() {
                    var popularEntries = this.entries.filter(entry => entry.payload === 'Popular');
                    var unpopularEntries = this.entries.filter(entry => entry.payload !== 'Popular');
                    var isPopularField = popularEntries.length && unpopularEntries.length;
                    return [
                        !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                            'value': '',
                            'key': 'null-option'
                        }, '\n          ', this.title, '\n        ') : null,
                        this.loading ? _createElement('option', {
                            'key': 'loading-option',
                            'disabled': true
                        }, '...loading...') : null,
                        isPopularField ? [
                            _createElement('option', {
                                'className': 'cm_option_title',
                                'disabled': true,
                                'key': '10541'
                            }, 'Popular ', this.title === 'Make' ? 'Makes' : this.title === 'Model' ? 'Models' : 'Values'),
                            _map(popularEntries, repeatEntry1.bind(this, popularEntries, unpopularEntries, isPopularField)),
                            _createElement('option', {
                                'className': 'cm_option_title',
                                'disabled': true,
                                'key': '10544'
                            }, 'Additional ', this.title === 'Make' ? 'Makes' : this.title === 'Model' ? 'Models' : 'Values')
                        ] : null,
                        _map(isPopularField ? unpopularEntries : this.entries, repeatEntry2.bind(this, popularEntries, unpopularEntries, isPopularField))
                    ];
                }
                function scopeShowSelectedValues4() {
                    var showSelectedValues = this.selectedEntries.length;
                    return _createElement('option', {
                        'key': '_current',
                        'value': '_current'
                    }, showSelectedValues ? [this.selectedEntries.map(e => e.value).join(', ')] : null, !showSelectedValues ? [this.title] : null);
                }
                function repeatEntry5(entry, index) {
                    return _createElement('div', {
                        'className': 'option' + (this.isSearchable && this.activeIndex === index || !this.isSearchable && entry.selected ? ' cm_checked' : ''),
                        'key': entry.value,
                        'onClick': () => this.onChange(entry.term)
                    }, this.showCheckboxes ? _createElement('input', {
                        'type': 'checkbox',
                        'readOnly': true,
                        'checked': entry.selected,
                        'className': 'cm_dropdown_checkbox cm_facet_checkbox',
                        'key': '3389'
                    }) : null, _createElement('div', { 'className': 'cm_dropdown_value' }, entry.value), this.showHitCount ? _createElement('div', {
                        'className': 'cm_dropdown_hitCount',
                        'key': '3591'
                    }, entry.hitCount) : null);
                }
                return _createElement('div', { 'className': this.extraClassName }, !this.isSearchable ? _createElement('div', {
                    'className': 'cm_select_toggle' + (this.useNativeDropdown ? ' cm_select_toggle__native' : '') + ' cm_toggle-dropdown',
                    'key': '39'
                }, _createElement('select', {
                    'className': 'cm_select_inner-select cm_select__pretty',
                    'onChange': this.onChange,
                    'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                    'disabled': this.disabled,
                    'aria-label': this.title
                }, this.useNativeDropdown ? scopePopularEntriesUnpopularEntriesIsPopularField3.apply(this, []) : null, !this.useNativeDropdown ? scopeShowSelectedValues4.apply(this, []) : null)) : null, this.isSearchable ? [[this.filterInput(function () {
                            return _createElement('div', { 'className': 'form-input cm_filterInput' });
                        }, {
                            widgetName: 'undefined',
                            items: undefined
                        })]] : null, !this.disabled && !this.useNativeDropdown ? _createElement.apply(this, [
                    'div',
                    {
                        'className': 'cm_dropdown cm_select_dropdown cm_hide',
                        'aria-label': this.title,
                        'key': '2699'
                    },
                    !this.hideNullOption ? _createElement('div', {
                        'className': 'option',
                        'key': 'null-option',
                        'onClick': () => this.onChange('')
                    }, '\n      ', this.title, '\n    ') : null,
                    this.loading ? _createElement('div', {
                        'className': 'option',
                        'key': 'null-option',
                        'disabled': true
                    }, '...loading...') : null,
                    !this.loading ? _map(this.entries, repeatEntry5.bind(this)) : null
                ]) : null);
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_vehicle-widget cm_vehicle-widget__verify-fitment' }, this.template === 'active' ? _createElement('div', {
        'className': 'cm_vehicle-widget_dropdowns cmTemplate_active',
        'key': '68'
    }, _createElement('div', { 'className': 'cm_vehicle-widget_title' }, window.Convermax.config?.verifyFitmentTitle || 'Verify fitment with your vehicle'), _createElement('div', { 'className': 'cm_vehicle-widget_body' }, _createElement.apply(this, [
        'div',
        { 'className': 'cm_vehicle-widget_select-container  cmRepeater_selects' },
        _map(this.selects, repeatSelects1.bind(this))
    ]), _createElement('div', { 'className': 'cm_vehicle-widget_button-container' }, _createElement('div', {
        'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__go button button--primary cm_primary',
        'onClick': this.selectVehicle,
        'disabled': !this.allowToSetVehicle,
        'role': 'button',
        'tabIndex': this.allowToSetVehicle ? '0' : '-1'
    }, '\n    GO\n  '), _createElement('div', {
        'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__clear button button--transparent cm_secondary',
        'onClick': this.discardVehicle,
        'disabled': !this.allowToDiscardVehicle,
        'role': 'button',
        'tabIndex': this.allowToDiscardVehicle ? '0' : '-1'
    }, [_createElement('svg', {
            'xmlns': 'http://www.w3.org/2000/svg',
            'className': 'cm_icon cm_icon-reset',
            'height': '16px',
            'viewBox': '0 0 16 16',
            'key': '68580'
        }, _createElement('path', { 'd': 'M2.083,9H0.062H0v5l1.481-1.361C2.932,14.673,5.311,16,8,16c4.08,0,7.446-3.054,7.938-7h-2.021 c-0.476,2.838-2.944,5-5.917,5c-2.106,0-3.96-1.086-5.03-2.729L5.441,9H2.083z' }), _createElement('path', { 'd': 'M8,0C3.92,0,0.554,3.054,0.062,7h2.021C2.559,4.162,5.027,2,8,2c2.169,0,4.07,1.151,5.124,2.876 L11,7h2h0.917h2.021H16V2l-1.432,1.432C13.123,1.357,10.72,0,8,0z' }))])))) : null, this.template === 'locked' ? _createElement('div', {
        'className': 'cm_vehicle-widget_label cm_verify-fitment cm_verify-fitment__' + this.fits + ' cmTemplate_locked',
        'key': '7384'
    }, _createElement('div', { 'className': 'cm_verify-fitment_fitment__container' }, _createElement('div', { 'className': 'cm_verify-fitment_icon' }, this.fits === 'yes' || this.fits === 'universal' ? [_createElement('svg', {
            'className': 'cm_icon cm_icon-check',
            'height': '20px',
            'role': 'img',
            'viewBox': '0 0 492 492',
            'key': '76360'
        }, _createElement('path', { 'd': 'm484.13 104.48-16.116-16.116c-5.064-5.068-11.816-7.856-19.024-7.856s-13.964 2.788-19.028 7.856l-226.45 226.45-141.48-141.49c-5.064-5.06-11.82-7.852-19.028-7.852-7.204 0-13.956 2.792-19.024 7.852l-16.12 16.112c-5.068 5.076-7.852 11.836-7.852 19.036 0 7.204 2.784 13.96 7.852 19.028l159.74 159.74c0.212 0.3 0.436 0.58 0.696 0.836l16.12 15.852c5.064 5.048 11.82 7.572 19.084 7.572h0.084c7.212 0 13.968-2.524 19.024-7.572l16.124-15.992c0.26-0.256 0.48-0.468 0.612-0.684l244.78-244.76c10.5-10.476 10.5-27.52 4e-3 -38.008z' }))] : null, this.fits === 'no' ? [_createElement('svg', {
            'className': 'cm_icon cm_icon-times',
            'height': '20px',
            'role': 'img',
            'viewBox': '0 0 22 22',
            'key': '83640'
        }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))] : null, this.fits === 'unknown' ? [_createElement('svg', {
            'className': 'cm_icon cm_icon-questionmark',
            'xmlns': 'http://www.w3.org/2000/svg',
            'viewBox': '0 0 512 512',
            'key': '85540'
        }, _createElement('path', {
            'd': 'M160 164s1.44-33 33.54-59.46C212.6 88.83 235.49 84.28 256 84c18.73-.23 35.47 2.94 45.48 7.82C318.59 100.2 352 120.6 352 164c0 45.67-29.18 66.37-62.35 89.18S248 298.36 248 324',
            'fill': 'none',
            'stroke': '#000',
            'strokeLinecap': 'round',
            'strokeMiterlimit': '10',
            'strokeWidth': '60'
        }), _createElement('circle', {
            'cx': '248',
            'cy': '399.99',
            'r': '32',
            'fill': '#000'
        }))] : null, this.fits === 'aggressive' ? [_createElement('svg', {
            'className': 'cm_icon cm_icon-exclamation',
            'height': '20',
            'viewBox': '0 0 256 255',
            'fill': 'none',
            'xmlns': 'http://www.w3.org/2000/svg',
            'key': '90620'
        }, _createElement('path', {
            'fillRule': 'evenodd',
            'clipRule': 'evenodd',
            'd': 'M112.098 0.528293C105.702 1.30529 89.9175 5.21029 83.6615 7.56329C64.7805 14.6653 49.2545 25.1013 34.5985 40.5423C-0.248547 77.2563 -9.69655 131.035 10.4785 177.833C17.3025 193.66 23.8655 203.124 37.5985 216.939C51.1805 230.602 61.9924 238.186 77.0984 244.649C104.915 256.549 138.414 258.074 166.974 248.741C177.998 245.139 194.9 236.465 203.598 229.946C212.511 223.266 228.177 206.84 233.748 198.332C262.341 154.67 262.457 100.262 234.048 56.8423C227.749 47.2143 210.42 29.3733 201.129 22.9513C185.936 12.4473 166.593 4.56829 148.217 1.39729C140.191 0.0112929 120.292 -0.466707 112.098 0.528293ZM146.598 101.364V157.864H127.598H108.598V101.364V44.8643H127.598H146.598V101.364ZM136.699 179.648C141.014 181.902 142.56 183.448 144.814 187.763C148.264 194.367 148.343 198.988 145.115 205.418C138.666 218.265 122.327 220.292 112.789 209.428C104.371 199.842 107.303 185.18 118.78 179.461C125.482 176.121 130.038 176.169 136.699 179.648Z'
        }))] : null), _createElement('div', { 'className': 'cm_verify-fitment_fitment' }, _createElement('div', { 'className': 'cm_verify-fitment_title' }, this.fits === 'yes' ? ['This Product Fits Your'] : null, this.fits === 'no' ? ['This Product DOES NOT Fit Your'] : null, this.fits === 'aggressive' ? ['Vehicle modifications may be required to fit your'] : null), _createElement('div', { 'className': 'cm_verify-fitment_body' }, _createElement('span', { 'className': 'cm_verify-fitment_vehicle' }, this.vehicleString || 'Vehicle'), _createElement('a', {
        'className': 'cm_verify-fitment_change',
        'onClick': this.changeVehicle
    }, 'Change')), this.fits === 'yes' ? [
        scopeVerifyFitmentInfo7.apply(this, []),
        this.productData?.wheel_mpn ? _createElement('button', {
            'className': 'cm_view-on-vehicle-btn button button--transparent cm_secondary',
            'onClick': e => {
                e.preventDefault();
                window.Convermax.openVisualizationDialog(this.productData.wheel_mpn);
            },
            'key': '109473'
        }, '\n            View On Vehicle\n          ') : null
    ] : null, this.fits === 'no' ? _createElement('div', {
        'className': 'cm_verify-fitment_compatible-parts-container',
        'key': '12702'
    }, _createElement('a', {
        'href': this.getVehicleUrl(),
        'className': 'cm_verify-fitment_compatible-parts'
    }, '\n            Click here for products that do fit your vehicle\n          ')) : null))) : null, this.template === 'inexact' ? _createElement('div', {
        'className': 'cm_vehicle-widget_specifier cmTemplate_inexact',
        'key': '13012'
    }, _createElement('div', { 'className': 'cm_vehicle-widget_title' }, 'Please specify ', this.fieldsToSpecify.join(', '), ' for your ', this.vehicleString), _createElement('div', { 'className': 'cm_vehicle-widget_body' }, _createElement.apply(this, [
        'div',
        { 'className': 'cm_vehicle-widget_select-container  cmRepeater_selects' },
        _map(this.selects, repeatSelects8.bind(this))
    ]), _createElement('div', { 'className': 'cm_vehicle-widget_button-container' }, _createElement('div', {
        'className': 'cm_vehicle-widget_button button button--primary button button--primary cm_primary',
        'onClick': this.selectVehicle,
        'disabled': !this.allowToSetVehicle,
        'role': 'button',
        'tabIndex': this.allowToSetVehicle ? '0' : '-1'
    }, '\n    GO\n  ')))) : null, this.template === 'universal' ? _createElement('div', {
        'className': 'cm_vehicle-widget_label cm_verify-fitment cm_verify-fitment__universal cmTemplate_universal',
        'key': '19541'
    }, _createElement('div', { 'className': 'cm_verify-fitment_icon' }, this.fits === 'yes' || this.fits === 'universal' ? [_createElement('svg', {
            'className': 'cm_icon cm_icon-check',
            'height': '20px',
            'role': 'img',
            'viewBox': '0 0 492 492',
            'key': '197400'
        }, _createElement('path', { 'd': 'm484.13 104.48-16.116-16.116c-5.064-5.068-11.816-7.856-19.024-7.856s-13.964 2.788-19.028 7.856l-226.45 226.45-141.48-141.49c-5.064-5.06-11.82-7.852-19.028-7.852-7.204 0-13.956 2.792-19.024 7.852l-16.12 16.112c-5.068 5.076-7.852 11.836-7.852 19.036 0 7.204 2.784 13.96 7.852 19.028l159.74 159.74c0.212 0.3 0.436 0.58 0.696 0.836l16.12 15.852c5.064 5.048 11.82 7.572 19.084 7.572h0.084c7.212 0 13.968-2.524 19.024-7.572l16.124-15.992c0.26-0.256 0.48-0.468 0.612-0.684l244.78-244.76c10.5-10.476 10.5-27.52 4e-3 -38.008z' }))] : null, this.fits === 'no' ? [_createElement('svg', {
            'className': 'cm_icon cm_icon-times',
            'height': '20px',
            'role': 'img',
            'viewBox': '0 0 22 22',
            'key': '204680'
        }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))] : null, this.fits === 'unknown' ? [_createElement('svg', {
            'className': 'cm_icon cm_icon-questionmark',
            'xmlns': 'http://www.w3.org/2000/svg',
            'viewBox': '0 0 512 512',
            'key': '206580'
        }, _createElement('path', {
            'd': 'M160 164s1.44-33 33.54-59.46C212.6 88.83 235.49 84.28 256 84c18.73-.23 35.47 2.94 45.48 7.82C318.59 100.2 352 120.6 352 164c0 45.67-29.18 66.37-62.35 89.18S248 298.36 248 324',
            'fill': 'none',
            'stroke': '#000',
            'strokeLinecap': 'round',
            'strokeMiterlimit': '10',
            'strokeWidth': '60'
        }), _createElement('circle', {
            'cx': '248',
            'cy': '399.99',
            'r': '32',
            'fill': '#000'
        }))] : null, this.fits === 'aggressive' ? [_createElement('svg', {
            'className': 'cm_icon cm_icon-exclamation',
            'height': '20',
            'viewBox': '0 0 256 255',
            'fill': 'none',
            'xmlns': 'http://www.w3.org/2000/svg',
            'key': '211660'
        }, _createElement('path', {
            'fillRule': 'evenodd',
            'clipRule': 'evenodd',
            'd': 'M112.098 0.528293C105.702 1.30529 89.9175 5.21029 83.6615 7.56329C64.7805 14.6653 49.2545 25.1013 34.5985 40.5423C-0.248547 77.2563 -9.69655 131.035 10.4785 177.833C17.3025 193.66 23.8655 203.124 37.5985 216.939C51.1805 230.602 61.9924 238.186 77.0984 244.649C104.915 256.549 138.414 258.074 166.974 248.741C177.998 245.139 194.9 236.465 203.598 229.946C212.511 223.266 228.177 206.84 233.748 198.332C262.341 154.67 262.457 100.262 234.048 56.8423C227.749 47.2143 210.42 29.3733 201.129 22.9513C185.936 12.4473 166.593 4.56829 148.217 1.39729C140.191 0.0112929 120.292 -0.466707 112.098 0.528293ZM146.598 101.364V157.864H127.598H108.598V101.364V44.8643H127.598H146.598V101.364ZM136.699 179.648C141.014 181.902 142.56 183.448 144.814 187.763C148.264 194.367 148.343 198.988 145.115 205.418C138.666 218.265 122.327 220.292 112.789 209.428C104.371 199.842 107.303 185.18 118.78 179.461C125.482 176.121 130.038 176.169 136.699 179.648Z'
        }))] : null), _createElement('div', { 'className': 'cm_verify-fitment_fitment' }, _createElement('div', { 'className': 'cm_verify-fitment_title' }, window.Convermax.config?.verifyFitmentUniversalTitle || window.Convermax.config?.fitmentUniversalTitle || 'Universal Fit'), _createElement('div', { 'className': 'cm_verify-fitment_notes' }, window.Convermax.config?.verifyFitmentUniversalText || window.Convermax.config?.fitmentUniversalText || 'This product may require modification.'))) : null, this.template === 'unknown' ? _createElement('div', {
        'className': 'cm_vehicle-widget_label cm_verify-fitment cm_verify-fitment__unknown cmTemplate_unknown',
        'key': '22813'
    }, _createElement('div', { 'className': 'cm_verify-fitment_icon' }, this.fits === 'yes' || this.fits === 'universal' ? [_createElement('svg', {
            'className': 'cm_icon cm_icon-check',
            'height': '20px',
            'role': 'img',
            'viewBox': '0 0 492 492',
            'key': '230060'
        }, _createElement('path', { 'd': 'm484.13 104.48-16.116-16.116c-5.064-5.068-11.816-7.856-19.024-7.856s-13.964 2.788-19.028 7.856l-226.45 226.45-141.48-141.49c-5.064-5.06-11.82-7.852-19.028-7.852-7.204 0-13.956 2.792-19.024 7.852l-16.12 16.112c-5.068 5.076-7.852 11.836-7.852 19.036 0 7.204 2.784 13.96 7.852 19.028l159.74 159.74c0.212 0.3 0.436 0.58 0.696 0.836l16.12 15.852c5.064 5.048 11.82 7.572 19.084 7.572h0.084c7.212 0 13.968-2.524 19.024-7.572l16.124-15.992c0.26-0.256 0.48-0.468 0.612-0.684l244.78-244.76c10.5-10.476 10.5-27.52 4e-3 -38.008z' }))] : null, this.fits === 'no' ? [_createElement('svg', {
            'className': 'cm_icon cm_icon-times',
            'height': '20px',
            'role': 'img',
            'viewBox': '0 0 22 22',
            'key': '237340'
        }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))] : null, this.fits === 'unknown' ? [_createElement('svg', {
            'className': 'cm_icon cm_icon-questionmark',
            'xmlns': 'http://www.w3.org/2000/svg',
            'viewBox': '0 0 512 512',
            'key': '239240'
        }, _createElement('path', {
            'd': 'M160 164s1.44-33 33.54-59.46C212.6 88.83 235.49 84.28 256 84c18.73-.23 35.47 2.94 45.48 7.82C318.59 100.2 352 120.6 352 164c0 45.67-29.18 66.37-62.35 89.18S248 298.36 248 324',
            'fill': 'none',
            'stroke': '#000',
            'strokeLinecap': 'round',
            'strokeMiterlimit': '10',
            'strokeWidth': '60'
        }), _createElement('circle', {
            'cx': '248',
            'cy': '399.99',
            'r': '32',
            'fill': '#000'
        }))] : null, this.fits === 'aggressive' ? [_createElement('svg', {
            'className': 'cm_icon cm_icon-exclamation',
            'height': '20',
            'viewBox': '0 0 256 255',
            'fill': 'none',
            'xmlns': 'http://www.w3.org/2000/svg',
            'key': '244320'
        }, _createElement('path', {
            'fillRule': 'evenodd',
            'clipRule': 'evenodd',
            'd': 'M112.098 0.528293C105.702 1.30529 89.9175 5.21029 83.6615 7.56329C64.7805 14.6653 49.2545 25.1013 34.5985 40.5423C-0.248547 77.2563 -9.69655 131.035 10.4785 177.833C17.3025 193.66 23.8655 203.124 37.5985 216.939C51.1805 230.602 61.9924 238.186 77.0984 244.649C104.915 256.549 138.414 258.074 166.974 248.741C177.998 245.139 194.9 236.465 203.598 229.946C212.511 223.266 228.177 206.84 233.748 198.332C262.341 154.67 262.457 100.262 234.048 56.8423C227.749 47.2143 210.42 29.3733 201.129 22.9513C185.936 12.4473 166.593 4.56829 148.217 1.39729C140.191 0.0112929 120.292 -0.466707 112.098 0.528293ZM146.598 101.364V157.864H127.598H108.598V101.364V44.8643H127.598H146.598V101.364ZM136.699 179.648C141.014 181.902 142.56 183.448 144.814 187.763C148.264 194.367 148.343 198.988 145.115 205.418C138.666 218.265 122.327 220.292 112.789 209.428C104.371 199.842 107.303 185.18 118.78 179.461C125.482 176.121 130.038 176.169 136.699 179.648Z'
        }))] : null), _createElement('div', { 'className': 'cm_verify-fitment_fitment' }, _createElement('div', { 'className': 'cm_verify-fitment_title' }, window.Convermax.config?.fitmentUnknownTitle || 'No Fitment Data'), _createElement('div', { 'className': 'cm_verify-fitment_notes' }, window.Convermax.config?.fitmentUnknownText || `This product doesn't have fitment application data. Please verify fitment manually.`))) : null);
}
        export const componentNames = ["cm:filterInput","cm:filterInput"]